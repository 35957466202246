@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.list-group-item-wrapper {
    .list-group-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: none;

        &.subitem {
            padding-left: 4rem;
            font-weight: normal;
        }
    }
}
