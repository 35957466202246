@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.col-links {
    display: flex;
    align-items: center;

    > div {
        flex-grow: 1;
    }
}
.footer {
    background-color: rgb(3, 32, 76);
    height: 150px;
}

.footer-logo {
    max-height: 100px;
    width: 100%;
    height: 100%;
}
