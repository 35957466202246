@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.block-title:after {
    display: none;
}
