@import "../../../../../../common/styles/user-variables";
@import "../../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.check-list {
    .row {
        margin-bottom: 1rem;
    }

    [class*="col-"] {
        padding-left: 10px;
        padding-right: 10px;
    }

    .form-group {
        margin-bottom: 0;
    }

    .date-group {
        display: flex;
        align-items: center;
    }
}
