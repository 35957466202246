//
// Popover
// --------------------------------------------------

.popover.bs-popover-bottom .arrow::after {
    border-bottom-color: $popover-header-bg;
}
.popover-header {
    font: {
        size: $font-size-md;
        weight: 700;
    }
}
