@import "../../vendor/dataTables.bootstrap4.css";
@import "../../vendor/vendor/vendor.min.css";
@import "~cookieconsent/build/cookieconsent.min.css";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~@riophae/vue-treeselect/dist/vue-treeselect.css";
@import "../../common/styles/main";

@keyframes fadein {
    from {
        opacity: 0;
    }
}

body {
    background-color: #f5f5f5;
    font: 0.875rem "Lucida Grande", Helvetica, Arial, sans-serif;
}

select:invalid {
    color: $gray-500;
}

.layout {
    background-color: #fafafa;
}

.text-tertiary {
    color: $tertiary !important;
}

.text-custom {
    color: $custom;
}

/* Sidebar navigation */
.sidebar {
    padding-left: 0;
    padding-right: 0;
}

.sidebar .nav {
    margin-bottom: 20px;
}

.sidebar .nav-item {
    width: 100%;
}

.sidebar .nav-item + .nav-item {
    margin-left: 0;
}

.sidebar .nav-link {
    border-radius: 0;
}

.spinner-box {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    background: #fff;
}

.control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: left;

    &.has-icons-left {
        input {
            padding-left: 2.25em;
        }

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 2.25em;
        }
    }
}

.multiselect {
    &,
    .multiselect__input,
    .multiselect__single {
        font-size: 0.75rem;
    }

    .multiselect__option {
        line-height: 0.75rem;
        min-height: 20px;

        &:after {
            line-height: 0.75rem;
            min-height: 20px;
            padding-top: 12px;
            padding-bottom: 12px;
        }
    }

    .multiselect__option--highlight {
        background: $primary;

        &:after {
            background: $primary;
        }
    }

    .multiselect__spinner {
        &:before,
        &:after {
            border-color: $primary transparent transparent;
        }
    }

    .multiselect__tags {
        border-radius: 0;
    }

    .multiselect__tag {
        background: $primary;
    }

    .multiselect__tag-icon {
        &:after {
            color: darken($primary, 20%);
        }

        &:focus,
        &:hover {
            background: darken($primary, 10%);
        }

        &:focus:after,
        &:hover:after {
            color: white;
        }
    }

    .multiselect__option--selected.multiselect__option--highlight {
        background: #ff6a6a;

        &:after {
            background: #ff6a6a;
            content: attr(data-deselect);
        }
    }

    input::placeholder {
        color: #adadad;
    }
}

.bar {
    min-height: 2rem;
    color: #fff;
    text-align: center;
    padding: 0.25rem;

    &.primary {
        background-color: $primary;
    }

    &.accent {
        background-color: $accent;
    }

    &.tertiary {
        background-color: $tertiary;
    }

    &.custom {
        background-color: $custom;
    }

    &.rocket {
        background-image: url("../images/rocket_launch.jpg");
        background-position: bottom;
        background-size: cover;
    }

    &.gradient {
        @include gradient-background($gradient-color-1, $gradient-color-2);
    }
}

.ticket-field {
    margin: 1rem;
    flex-grow: 1;
    text-align: center;

    .title {
        font-weight: bold;
        margin-bottom: 0.5rem;
    }
}

.divider {
    margin: 1rem auto;
    width: 36px;
    height: 2px;
    @extend .bg-gradient;
}

.truncated {
    overflow: hidden;
    text-overflow: ellipsis;
}

.card {
    &.flat-card {
        box-shadow: none;
        background-color: transparent;
    }

    &.hoverable:hover {
        box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.15);
    }
}

.GSE-layout-3-line-text-ellipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
