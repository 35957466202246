//
// Tables
// --------------------------------------------------

.table {
    thead th,
    th {
        font-weight: 700;
    }
}
