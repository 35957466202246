@import "../../../../../../common/styles/user-variables";
@import "../../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.signin-box {
    display: flex;
    justify-content: center;
    border-radius: 4px;

    form {
        width: 400px;
    }

    .wizard-footer {
        display: flex;
        justify-content: space-between;
    }

    .logo-icon {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}

.signup-action-btn {
    border: 1px solid #19799f;
    border-radius: 4px;
    background-color: white;
    color: #19799f;
    width: 200px;
    box-shadow: 0px 3px 6px grey;
}
