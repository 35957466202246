@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.avatar-letter {
    background-color: #dbdbdb;
    color: #4a4a4a;
    font-size: 2rem;
    font-weight: bold;
    height: 100%;
}

body {
    background-color: #efefef;
}

.file-upload {
    display: none;
}
.circle {
    border-radius: 1000px !important;
    width: 85px;
    height: 85px;
    border: 8px solid rgba(255, 255, 255, 0.7);
    overflow: hidden;
}

.circle:hover + .p-image {
    opacity: 1;
}

.p-image:hover {
    opacity: 1;
}

img {
    max-width: 100%;
    height: auto;
}
.p-image {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    color: #666666;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    left: 90%;
    top: 90%;
    transform: translate(-50%, -50%);
}
.p-image:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.upload-button {
    font-size: 1.2em;
}

.upload-button:hover {
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    color: #999;
}
