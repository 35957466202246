@import "../../../../../../common/styles/user-variables";
@import "../../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.signin-box {
    display: flex;
    justify-content: center;
    border-radius: 4px;

    form {
        width: 400px;
    }

    .wizard-footer {
        display: flex;
        justify-content: space-between;
    }

    .logo-icon {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}
#partitioned {
    padding-left: 9px;
    letter-spacing: 23px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 32px 1px;
    background-repeat: repeat-x;
    background-position-x: 24px;
    width: 220px;
    min-width: 220px;
    outline: none;
}

#divInner {
    left: 0;
    position: sticky;
}

#divOuter {
    margin: 12px auto;
    width: 190px;
    overflow: hidden;
}
