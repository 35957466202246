//
// Modal
// --------------------------------------------------

.modal-header {
    position: relative;
    .close {
        position: absolute;
        top: 0.625rem;
        right: 0.5rem;
        margin: 0;
        padding: 0 0.3rem;
    }
}
.modal-title {
    font-size: $h5-font-size;
}
