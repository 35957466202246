//
// Dropdown
// --------------------------------------------------

.dropdown-menu {
    box-shadow: $dropdown-box-shadow;
}
.dropdown-toggle::after,
.dropright .dropdown-toggle::after,
.dropleft .dropdown-toggle::before {
    margin-top: -0.0625rem;
    vertical-align: middle;
}
.dropright .dropdown-toggle::after {
    margin-top: -0.125rem;
    margin-left: 0.25rem;
    border-left: 0.4em solid;
}
.dropleft .dropdown-toggle::before {
    margin-top: -0.125rem;
    margin-right: 0.25rem;
}
.dropdown-header,
.dropdown-item,
.dropdown-item-text {
    padding: ($spacer * 0.75) ($spacer * 1.25);
    > i {
        display: inline-block;
        vertical-align: middle;
    }
}
.lang-dropdown {
    min-width: 150px;
}
.dropdown-item,
li.dropdown-item > a,
.dropdown-item-text {
    margin: 0;
    transition: color 0.3s;
    border-top: 1px solid $dropdown-border-color;
    color: $dropdown-link-color;
    font: {
        size: $dropdown-item-size;
        weight: 600;
    }
    text: {
        decoration: none;
    }
    &:focus {
        background-color: transparent;
    }
    &:first-child {
        border-top: 0;
    }
    > img {
        display: inline-block;
        width: 20px;
        margin: {
            top: -0.0625rem;
            right: 0.375rem;
        }
        vertical-align: middle;
    }
}
.dropdown-item,
li.dropdown-item > a {
    &:hover {
        background-color: $dropdown-link-hover-bg;
        color: $dropdown-link-hover-color;
    }
    &.active {
        background-color: $dropdown-link-active-bg;
        color: $dropdown-link-active-color;
    }
}
li.dropdown-item {
    padding: 0;
    > a {
        display: block;
        padding: 0.75rem 1.25rem;
    }
    &:hover,
    &.active {
        > a,
        > a::after {
            color: $primary !important;
        }
    }
}
.dropdown-item.has-children {
    position: relative;
    > a {
        position: relative;
        padding-right: 32px;
        &::after {
            display: block;
            position: absolute;
            top: 50%;
            right: 0.75rem;
            margin-top: -0.75rem;
            transition: color 0.3s;
            color: $gray-800;
            font: {
                family: Feather;
                size: $font-size-base;
                weight: normal;
            }
            content: "\e930";
        }
    }
    > .dropdown-menu {
        top: -1px;
        left: 100%;
    }
    &:hover > .dropdown-menu {
        display: block;
        animation: dropdown-slide-right 0.3s;
    }
}
.right-aligned .dropdown-item.has-children {
    > .dropdown-menu {
        right: 100%;
        left: auto;
    }
    &:hover > .dropdown-menu {
        animation: dropdown-slide-left 0.3s;
    }
}
.dropdown-item-text {
    &:hover,
    &.active {
        color: $gray-800;
    }
}

.dropdown-header {
    font: {
        family: $font-family-base;
        size: $dropdown-item-size;
        weight: 700;
    }
    letter-spacing: 0.03em;
    > i {
        margin-top: -2px;
    }
}

.dropdown-divider {
    display: none;
}

// Mega Dropdown
.mega-dropdown {
    padding: 0;
    .column {
        width: 220px;
        padding: 30px 15px;
        &:first-child {
            margin-left: 15px;
        }
        &:last-child {
            margin-right: 15px;
        }
    }
    .widget {
        margin: 0;
        .widget-title {
            color: $gray-700;
            font: {
                family: $font-family-base;
                size: ($font-size-base * 0.8125);
                weight: 600;
            }
            &::after {
                bottom: -2px;
                height: 2px;
            }
        }
        &.widget-custom-menu {
            ul > li > a {
                font-size: ($font-size-base * 0.8125);
            }
        }
    }
}

// Show Animation
.show .dropdown-menu {
    animation: dropdown-show 0.25s;
}
@keyframes dropdown-show {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes dropdown-slide-down {
    from {
        transform: translateY(-7px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
@keyframes dropdown-slide-right {
    from {
        transform: translateX(-7px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
@keyframes dropdown-slide-left {
    from {
        transform: translateX(7px);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
