@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.quiltyInsights-edit {
    .progress {
        background-color: #e3e3e3;
    }
    .nav-tabs .nav-link {
        height: 3rem;
        padding-left: 0.8rem;
        padding-right: 0.8rem;
    }
    .form-group.row {
        margin-bottom: 0;
    }
}
