//
// Gallery (based on Fancybox)
// --------------------------------------------------

.gallery-item {
    display: block;
    position: relative;
    width: 100%;
    transition: box-shadow 0.35s 0.12s;
    color: $white;
    text-decoration: none;
    > img {
        display: block;
        width: 100%;
    }
    &::before,
    &::after {
        display: block;
        position: absolute;
    }
    &::before {
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.35s 0.12s;
        @extend .bg-gradient-vertical;
        content: "";
        opacity: 0;
        z-index: 1;
    }
    &::after {
        top: 50%;
        left: 50%;
        width: $btn-height;
        height: $btn-height;
        margin: {
            top: -($btn-height / 2);
            left: -($btn-height / 2);
        }
        border-radius: 50%;
        background-color: $white;
        content: "";
        z-index: 5;
    }
    &:not(.type-video)::after {
        transform: scale(0.2);
        transition: all 0.3s 0.12s;
        color: $gray-700;
        font: {
            family: feather;
            size: ($font-size-base * 1.3125);
            weight: normal;
        }
        line-height: $btn-height;
        text-align: center;
        content: "\ea08";
        opacity: 0;
    }
    &.type-video::after {
        background: {
            position: center;
            image: url(data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTYuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjE2cHgiIGhlaWdodD0iMTZweCIgdmlld0JveD0iMCAwIDEyNC41MTIgMTI0LjUxMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTI0LjUxMiAxMjQuNTEyOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBhdGggZD0iTTExMy45NTYsNTcuMDA2bC05Ny40LTU2LjJjLTQtMi4zLTksMC42LTksNS4ydjExMi41YzAsNC42LDUsNy41LDksNS4ybDk3LjQtNTYuMiAgIEMxMTcuOTU2LDY1LjEwNSwxMTcuOTU2LDU5LjMwNiwxMTMuOTU2LDU3LjAwNnoiIGZpbGw9IiMzMzMzMzMiLz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K);
            repeat: no-repeat;
            size: 10px 10px;
        }
        box-shadow: $gallery-hover-box-shadow;
    }
    &:hover {
        box-shadow: $gallery-hover-box-shadow;
        &::before {
            opacity: 0.5;
        }
        &:not(.type-video)::after {
            transform: scale(1);
            opacity: 1;
        }
    }
}

// Inside carousel
.owl-carousel .gallery-item:hover {
    box-shadow: none;
}

// Fanctbox overrides
.fancybox-thumbs__list a::before {
    border-color: $primary;
}
.fancybox-thumbs__list a:focus::before {
    opacity: 0;
}
.fancybox-progress {
    background-color: $primary;
}
