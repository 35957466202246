@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.credentialFooter {
    background: white;

    @media (max-width: 992px) {
        height: 80px;
    }

    height: 40px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;

    a {
        text-decoration: none;
    }
}

@media (max-width: 768px) {
    .credentialFooter {
        display: flex;
        flex-direction: column;
        height: auto;
        gap: 10px;
    }
}
