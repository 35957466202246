@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.btn-add {
    height: initial;
    border-radius: 123123px;
    line-height: initial;
    padding-top: 0.15rem;
    padding-bottom: 0.15rem;
}
