//
// Badge
// --------------------------------------------------

.badge {
    margin: {
        top: -0.1875rem;
        left: 0.4375rem;
    }
    padding: {
        top: 0.2em;
        bottom: 0.3em;
    }
    border-radius: $badge-border-radius;
    vertical-align: middle;
    &:not(.badge-light) {
        color: $white;
    }
    &.badge-pill {
        border-radius: $badge-pill-border-radius;
    }
}
.badge-secondary {
    border: 1px solid $border-color;
    color: $body-color !important;
}
