.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.clickable {
    cursor: pointer;
}

.full-height {
    height: 100vh;
}

.image-box {
    & > img {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
}

.flex-right {
    display: flex;
    justify-content: flex-end;
}

.btn-custom {
    color: $white;
    background-color: $custom;

    &:hover {
        color: $white;
        background-color: lighten($custom, 10%);
    }

    &:active {
        color: $white;
        background-color: darken($custom, 10%);
    }
}

.btn.provide-feedback {
    line-height: 1.4;
    padding: 0.5rem;
    font-size: 0.7rem;
}
