@import "../../../../../../common/styles/user-variables";
@import "../../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.signup-action-btn {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: space-around;

    img {
        width: 50px;
    }
}

.signin-box {
    display: flex;
    justify-content: center;
    border-radius: 4px;

    form {
        width: 500px;
    }

    .wizard-footer {
        display: flex;
        justify-content: space-between;
    }

    .logo-icon {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}
