@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.block-title:after {
    display: none;
}

.export-button {
    cursor: pointer; /* Makes the cursor look like a hand, indicating clickable element */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
    padding: 10px 15px; /* Optional padding for better appearance */
    border-radius: 5px; /* Rounded corners for button-like appearance */
    text-align: center;
}

.export-button:hover {
    background-color: #f5f5f5; /* Light grey background on hover */
}

.export-button:focus {
    outline: none; /* Removes the default focus outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25); /* Adds a blue glow when element is focused */
}
