@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.signin-box {
    display: flex;
    justify-content: center;

    form {
        width: 400px;
    }

    .wizard-footer {
        display: flex;
        justify-content: space-between;
    }

    .logo-icon {
        padding-left: 0.7rem;
        padding-right: 0.7rem;
    }
}

.link {
    color: #eb405a;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        color: #eb405a;
        text-decoration: none;
    }
}

.spacer {
    margin: 8px 0;
}
