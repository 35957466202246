@import "../../../common/styles/user-variables";
@import "../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


@media screen and (max-width: 768px) {
    .content-grid {
        grid-template-columns: 100% !important;
        grid-template-areas:
            "right"
            "main"
            "left"
            "flyer" !important;
    }

    .sticky {
        position: relative !important;
    }
}

.content-grid {
    display: grid;
    grid-template-columns: 20% 56% 20%;
    grid-column-gap: 2%;
    grid-template-rows: auto;
    text-align: center;

    grid-template-areas:
        "left main right"
        "left flyer right"
        "left flyer right";
}

.sticky {
    position: sticky;
    top: 0;
}

.grid-item-flyer {
    grid-area: flyer;
}

.grid-item-video {
    grid-area: main;
}

.grid-item-buttons {
    grid-area: left;
}

.grid-item-conference {
    grid-area: right;
}
