@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout > div,
.layout > section {
    flex-grow: 1;
}
