@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


li {
    margin-bottom: 0;
}

.disabledLook {
    opacity: 0.4;
    cursor: not-allowed;
}
