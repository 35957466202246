@import "../../../../../common/styles/user-variables";
@import "../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.author-card-cover {
    #organizationLogo {
        display: none;
    }

    .btn-upload-logo,
    .btn-cancel-logo,
    .btn-save-logo {
        animation: fadein 0.2s;
    }

    .btn-upload-logo {
        display: none;
    }

    &:hover {
        .btn-upload-logo {
            display: block;
        }
    }

    &::after {
        background: none !important;
    }
}
