//
// user-variables.scss
// Use this to overwrite Bootstrap and CreateX variables
//

// Example of a variable override to change Dashkit's background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

$primary: #19799f;
$accent: #14b9a5;
$custom: #e69334;
$tertiary: #007c84;
