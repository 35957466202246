//
// Images
// --------------------------------------------------

// Figures
.figure {
    position: relative;
    padding: 0.9375rem;
    background-color: $white;
    box-shadow: $card-box-shadow;
    > .badge {
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
        margin: 0;
        z-index: 5;
    }
}
.figure-img {
    display: block;
    width: 100%;
    margin-bottom: 0.9375rem;
}
.figure-caption {
    font-weight: 600;
}
