//
// Placeholders
// --------------------------------------------------

// Divider
%divider {
    display: block;
    position: absolute;
    top: 100%;
    left: 1px;
    width: 36px;
    height: 2px;
    @include gradient-background($gradient-color-1, $gradient-color-2);
    content: "";
}

// Absolute Alignment Center
%center-xy {
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
}
