@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.member-view {
    .author-card-cover.default {
        background-image: url("../../../images/profile_cover.jpg");
    }
}
