@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.layout {
    .navbar-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;

        .protruding-brand {
            $protruding-height: 2.5rem;
            width: 280px;
            background-color: #fff;
            margin-bottom: -$protruding-height;
            height: calc(80px + #{$protruding-height});
            border-bottom-left-radius: 1rem;
            border-bottom-right-radius: 1rem;
            text-align: center;

            img {
                min-height: 90px;
            }

            .description {
                letter-spacing: -1px;
                font-size: 0.8rem;
                color: #e69334;
                font-weight: bold;
            }
        }
    }

    .user-dropdown {
        right: 0;
        left: initial;
    }

    .member-link {
        background: $white;
        border: 1px solid $gray-500;
        font-weight: bold;
    }
}
