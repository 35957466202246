//
// Grid
// --------------------------------------------------

// Bootstrap Overrides
@include media-breakpoint-down(lg) {
    .container {
        width: 100% !important;
        max-width: 100% !important;
    }
}
.container-fluid {
    max-width: 1920px;
    margin: {
        right: auto;
        left: auto;
    }
    padding: {
        right: 30px;
        left: 30px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0 15px;
    }
}

// Isotope based Grid
.isotope-grid {
    position: relative;
    &:not(.filter-grid) {
        min-height: 180px;
        background: {
            position: center;
            image: url(../../images/loading.gif);
            repeat: no-repeat;
            size: 74px;
        }
        > .grid-item {
            display: none;
        }
        &[style] {
            background: none;
            > .grid-item {
                display: inline-block;
                animation: showGrid 0.6s;
            }
        }
    }

    // Gap Width
    .gutter-sizer {
        width: 30px;
    }

    // Items
    .grid-item {
        margin-bottom: 30px;
        .post-tile,
        .portfolio-tile {
            margin-bottom: 0;
        }
    }

    // 1 Column
    &.cols-1 {
        .gutter-sizer {
            width: 0;
        }
        .grid-sizer,
        .grid-item {
            width: 100%;
        }
    }

    // 2 Columns
    &.cols-2 {
        .grid-sizer,
        .grid-item {
            width: calc((100% / 2) - 15px);
        }
    }

    // 3 Columns
    &.cols-3 {
        .grid-sizer,
        .grid-item {
            width: calc((100% / 3) - 20px);
        }
    }

    // 4 Columns
    &.cols-4 {
        .grid-sizer,
        .grid-item {
            width: calc((100% / 4) - 22.5px);
        }
    }

    // 5 Columns
    &.cols-5 {
        .grid-sizer,
        .grid-item {
            width: calc((100% / 5) - 24px);
        }
    }

    // 6 Columns
    &.cols-6 {
        .grid-sizer,
        .grid-item {
            width: calc((100% / 6) - 25px);
        }
    }

    // Media Queries
    @include media-breakpoint-down(lg) {
        &.cols-6,
        &.cols-5,
        &.cols-4,
        &.cols-3 {
            .grid-sizer,
            .grid-item {
                width: calc((100% / 3) - 22.5px);
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &.cols-6,
        &.cols-5,
        &.cols-4,
        &.cols-3 {
            .grid-sizer,
            .grid-item {
                width: calc((100% / 2) - 15px);
            }
        }
    }
    @include media-breakpoint-down(xs) {
        &.cols-6,
        &.cols-5,
        &.cols-4,
        &.cols-3,
        &.cols-2 {
            .gutter-sizer {
                width: 0;
            }
            .grid-sizer,
            .grid-item {
                width: 100%;
            }
        }
    }

    // No Gutters Version
    &.grid-no-gutters {
        .gutter-sizer {
            width: 0;
        }
        .grid-item {
            margin-bottom: 0;
        }
        &.cols-2 {
            .grid-sizer,
            .grid-item {
                width: (100% / 2);
            }
        }
        &.cols-3 {
            .grid-sizer,
            .grid-item {
                width: (100% / 3);
            }
        }
        &.cols-4 {
            .grid-sizer,
            .grid-item {
                width: (100% / 4);
            }
        }
        &.cols-5 {
            .grid-sizer,
            .grid-item {
                width: (100% / 5);
            }
        }
        &.cols-6 {
            .grid-sizer,
            .grid-item {
                width: (100% / 6);
            }
        }
        @include media-breakpoint-down(lg) {
            &.cols-6,
            &.cols-5,
            &.cols-4,
            &.cols-3 {
                .grid-sizer,
                .grid-item {
                    width: (100% / 3);
                }
            }
        }
        @include media-breakpoint-down(sm) {
            &.cols-6,
            &.cols-5,
            &.cols-4,
            &.cols-3 {
                .grid-sizer,
                .grid-item {
                    width: (100% / 2);
                }
            }
        }
        @include media-breakpoint-down(xs) {
            &.cols-6,
            &.cols-5,
            &.cols-4,
            &.cols-3,
            &.cols-2 {
                .grid-sizer,
                .grid-item {
                    width: 100%;
                }
            }
        }
    }
}

@keyframes showGrid {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

// Microsoft Edge specific styles
@supports (-ms-ime-align: auto) {
    .isotope-grid:not(.filter-grid) {
        min-height: auto;
        background: none;
        > .grid-item {
            display: inline-block;
        }
        &[style] {
            > .grid-item {
                animation: none;
            }
        }
    }
}
