@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.page-title {
    background-image: url("../../../images/shop_pattern.jpg");
    background-position-x: right;
}
