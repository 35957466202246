@import "../../../../../../common/styles/user-variables";
@import "../../../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.author-card {
    background-color: #ddd;

    .author-card-cover {
        height: 200px;
    }
}
