@import "../../../../common/styles/user-variables";
@import "../../../../vendor/createx/styles/helpers";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";


.layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.layout > div,
.layout > section {
    flex-grow: 1;
}

.signin-image {
    max-width: none; /* Set a maximum width to limit the size on larger screens */
    margin: 0 10%;
    height: auto; /* Maintain the aspect ratio */
    transform: none; /* Remove the transform for smaller screens */
}

@media (min-width: 650px) {
    .signin-image {
        margin: 0 25%;
    }
}

@media (min-width: 768px) {
    .signin-image {
        margin: auto;
        transform: translate(-10%, -30%);
        max-width: 300px; /* Increase width for medium (md) screens */
    }
}

@media (min-width: 992px) {
    .signin-image {
        max-width: 350px; /* Increase width for large (lg) screens */
    }
}

@media (min-width: 1200px) {
    .signin-image {
        max-width: 350px; /* Increase width for extra-large (xl) screens */
    }
}
