//
// Tabs, Pills and Filters
// --------------------------------------------------

// Nav Link
.nav-link {
    transition: $nav-link-transition;
}

// Tabs
// --------------------------------------------------
.nav-tabs {
    justify-content: center;
    padding-bottom: 0.75rem;
    border: 0;
    .nav-link {
        position: relative;
        margin: 0 0.3125rem 0.625rem;
        background-color: darken($gray-100, 3%);
        color: $gray-700;
        font: {
            size: $font-size-sm;
            weight: bold;
        }
        letter-spacing: 0.1em;
        white-space: nowrap;
        text: {
            transform: uppercase;
            decoration: none;
        }
        > i {
            display: inline-block;
            margin-top: -0.125rem;
            font-size: 1.1em;
            vertical-align: middle;
        }
        > sup {
            transition: color 0.3s;
            color: $gray-500;
            font-size: $font-size-xs;
        }
        &:hover {
            background-color: $white;
            box-shadow: 0 0.75rem 1.25rem 0.0625rem rgba($gray-700, 0.1);
        }
        &.active {
            background-color: $primary;
            color: $white;
            box-shadow: none;
            cursor: default;
            > sup {
                color: rgba($white, 0.7);
            }
        }
    }
    .show .dropdown-toggle {
        background-color: darken($gray-100, 3%) !important;
        box-shadow: none;
    }
    &.nav-tabs-bordered {
        flex-wrap: nowrap;
        .nav-link.active {
            z-index: 1;
            &::after,
            &::before {
                position: absolute;
                bottom: -1.375rem;
                left: 50%;
                width: 0;
                height: 0;
                border: solid transparent;
                content: "";
                pointer-events: none;
            }
            &::after {
                margin-left: -9px;
                border-width: 9px;
                border-color: rgba($white, 0);
                border-bottom-color: $white;
            }
            &::before {
                margin-left: -10px;
                border-width: 10px;
                border-color: rgba($border-color, 0);
                border-bottom-color: darken($border-color, 5%);
            }
        }
        @include media-breakpoint-down(sm) {
            flex-wrap: wrap;
            .nav-link.active {
                &::after,
                &::before {
                    display: none;
                }
            }
        }
    }

    // Style 2, 3, 4
    &.nav-tabs-style-2,
    &.nav-tabs-style-3,
    &.nav-tabs-style-4 {
        .nav-link {
            padding-bottom: 0.375rem;
            border-bottom: 3px solid $border-color;
            background-color: transparent;
            &:hover {
                border-color: $primary;
                box-shadow: 0 0.75rem 0.875rem -0.5625rem rgba($primary, 0.3);
            }
            &.active {
                border-color: $primary;
                background-color: $primary;
            }
        }
        &.nav-tabs-bordered .nav-link.active {
            &::after,
            &::before {
                bottom: -1.5625rem;
            }
        }
        .show .dropdown-toggle {
            border-color: $border-color;
            background: none !important;
            box-shadow: none;
        }
    }
    &.nav-tabs-style-3 {
        .nav-link {
            color: $gray-500;
            &.active {
                border-color: $primary;
                background-color: transparent;
                color: $gray-700;
                box-shadow: none;
                > sup {
                    color: $gray-500;
                }
            }
        }
    }
    &.nav-tabs-style-4 {
        margin-bottom: 1.375rem;
        padding-bottom: 0;
        box-shadow: 0 0.625rem 1.375rem -0.3125rem rgba($gray-700, 0.12);
        .nav-link {
            margin-bottom: 0;
            border-bottom-color: transparent;
            &:hover {
                border-color: transparent;
                color: $primary;
                box-shadow: none;
                > sup {
                    color: rgba($primary, 0.7);
                }
            }
            &.active {
                border-color: $primary;
                background-color: transparent;
                color: $primary;
                box-shadow: 0 0.75rem 0.875rem -0.5625rem rgba($primary, 0.3);
                > sup {
                    color: rgba($primary, 0.7);
                }
            }
        }
        .show .dropdown-toggle {
            border-color: transparent;
            color: $primary;
            box-shadow: none;
        }
    }

    // Style 5
    &.nav-tabs-style-5 {
        .nav-link {
            border: 1px solid $border-color;
            border-radius: 18px;
            background-color: transparent;
            &:hover {
                border-color: transparent;
            }
            &.active {
                border-color: $primary;
                background-color: $primary;
            }
        }
        .show .dropdown-toggle {
            border-color: $border-color;
            background-color: transparent !important;
        }
        &.nav-tabs-bordered {
            .nav-link.active {
                &::before,
                &::after {
                    bottom: -1.5rem;
                }
            }
        }
    }
}

// Tab content
.nav-tabs-bordered + .tab-content {
    margin-top: -1px;
    padding: ($spacer * 0.875) ($spacer * 1.25);
    border: 1px solid $border-color;
    background-color: $white;
    p:last-child,
    ul:last-child,
    ol:last-child {
        margin-bottom: 0;
    }
}

// Pills
// --------------------------------------------------
.nav-pills {
    .nav-item {
        margin: {
            right: 0.3125rem;
            bottom: 0.5rem;
        }
    }
    .nav-link {
        border: 1px solid transparent;
        color: $gray-700;
        font: {
            size: $font-size-sm;
            weight: bold;
        }
        letter-spacing: 0.1em;
        white-space: nowrap;
        text: {
            transform: uppercase;
            decoration: none;
        }
        white-space: nowrap;
        > i {
            display: inline-block;
            margin-top: -0.125rem;
            font-size: 1.1em;
            vertical-align: middle;
        }
        &:hover {
            border-color: $border-color;
            background-color: $gray-100;
        }
        &.active {
            background-color: $primary;
        }
        &.disabled {
            border-color: transparent !important;
            background: none !important;
            color: $gray-500 !important;
            cursor: default;
            pointer-events: none;
        }
    }
    .nav-item.show .nav-link,
    .nav-link.active {
        border-color: $primary !important;
        background-color: $primary !important;
        color: $white;
        cursor: default;
    }
    &.nav-pills-style-2 {
        .nav-link {
            border-radius: 19px;
        }
    }
}

// Nav justified
.nav-justified {
    .nav-item,
    .nav-link {
        margin: {
            right: 0 !important;
            left: 0 !important;
        }
    }
}

// Component Animations
.transition {
    &.fade {
        transition: all 0.4s ease-in-out;
        opacity: 0;
        backface-visibility: hidden;
        &.show {
            opacity: 1;
        }
    }
    &.scale.fade {
        transform: scale(0.9);
    }
    &.scaledown.fade {
        transform: scale(1.1);
    }
    &.scale.fade.show,
    &.scaledown.fade.show {
        transform: scale(1);
    }
    &.left.fade {
        transform: translateX(40px);
    }
    &.right.fade {
        transform: translateX(-40px);
    }
    &.left.fade.show,
    &.right.fade.show {
        transform: translateX(0);
    }
    &.top.fade {
        transform: translateY(-40px);
    }
    &.bottom.fade {
        transform: translateY(40px);
    }
    &.top.fade.show,
    &.bottom.fade.show {
        transform: translateY(0);
    }
    &.flip.fade {
        transform: rotateY(-90deg) scale(1.1);
        transform-origin: 50% 50%;
    }
    &.flip.fade.show {
        transform: rotateY(0deg) scale(1);
        transform-origin: 50% 50%;
    }
}
